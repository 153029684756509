<template>
    <div class="main">
        <div class="doc-content">
            <p>
            <p class="MsoNormal">
                欢迎您使用人康平台服务！
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="MsoNormal">
                特别提示：
            </p>
            <p class="MsoNormal">
                在使用人康平台服务之前，您应当认真阅读并遵守《人康<span>平台服务协议》（以下简称</span>“本协议”），<b>请您务必审慎阅读、充分理解各条款内容，特别是本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，您应重点阅读。</b>如您对协议有任何疑问的，应向人康平台客服咨询。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="MsoNormal">
                <b><span>您在本协议前点击</span>“√”后</b><b>或同意本协议后</b><b>，即表示您完全理解并接受本协议项下的全部条款，同意将其作为确定双方权利义务的依据，本协议即构成对双方有约束力的法律文件。如果您不同意本协议的任意内容，或者无法准确理解本公司对条款的解释，请不要进行后续操作，包括但不限于不接受本协议，不使用本服务。</b><span>您承诺接受并遵守本协议的约定，</span> 届时您不应以未阅读本协议的内容或者未获得人康平台对您问询的解答等为由，主张本协议无效，或要求撤销本协议。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                一、&nbsp;协议范围
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;本协议由您与人康平台的经营者石家庄守正企业管理咨询有限公司共同签署，本协议具有法律效力。人康平台的经营者是指法律认可的经营人康应用软件、人康微信端口、人康网页端、人康业务管理平台等的责任主体。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                2.&nbsp;除另行明确声明外，人康平台服务包含任何人康及其关联公司提供的基于互联网以及移动网的相关服务，且均受本协议约束。如果您不同意本协议的约定，您应立即停止注册或停止使用人康平台服务。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                3.&nbsp;<b>本协议内容包含</b><b>协议正文、法律声明、</b><b><span>现在及将来本公司通过网络或通讯方式告知您的各项操作提示、规则、公告、说明等（以下统称</span>“服务规则”），所有服务规则均为本协议不可分割的一部分，与本协议具有同等法律效力。</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                4.&nbsp;<b>人康</b><b>依据公平、公正、合理、合法原则有权根据需要不时地制订、修改本协议及各类规则，</b><b>并以网站公示</b><b><span>、</span>APP通知</b><b>的方式进行变更公告，无需另行单独通知您。</b>变更后的协议和规则一经在网站<span>、</span>APP公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用人康平台服务的，即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用人康平台服务。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                二、&nbsp;用户注册与账户
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;<b>主体资格：在您完成注册程序或以其他</b><b>人康</b><b>平台允许的方式使用</b><b>人康</b><b>时，您应当是具备完全民事行为能力和权利能力的自然人、法人或其他组织。</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                2.&nbsp;注册和账户
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                a)&nbsp;人康平台的会员主要分为两类：待认证会员和已认证会员。待认证会员和已认证会员依据人康的服务规则开通不同的服务内容和权限。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                b)&nbsp;当您照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可以使用您提供或确认的邮箱、手机号码或者人康允许的其它方式作为登录手段进入人康平台。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                c)&nbsp;<b>您的登录名、</b><b>人康</b><b>账户名称和密码不得以任何方式买卖、转让、赠与或继承，</b>除非有法律明确规定或司法裁定，并经人康同意，且需提供人康要求的合格的文件材料并根据人康制定的操作流程办理。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                3.&nbsp;用户信息
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                a)&nbsp;在您注册时，应当依照法律法规的要求，根据人康<span>相应页面的提示准确填写、上传各项资料，并在资料信息变更</span>5日内及时进行用户信息更新，以确保提交的信息真实、准确、完整。<b>若因用户未及时更新资料，导致有关流程和操作无法完成或发生错误，由此产生的一切后果和责任由用户承担。</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                b)&nbsp;您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便人康平台与您进行有效联系，<b>因通过这些联系方式无法与您取得联系，导致您在使用</b><b>人康</b><b>平台服务过程中产生任何损失或增加费用的，应由您完全独自承担。</b>您了解并同意，您有义务保持你提供的联系方式的真实性和有效性，如有变更或需要更新的，您应按人康的要求进行操作。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                4.&nbsp;账户安全
            </p>
            <p class="MsoNormal">
                您需自行负责对您的人康账户名称、密码等的保管及保密，您对在该登录账号及密码下发生的所有行为承担责任。<span>您同意：</span>(a) 如发现任何人未经授权使用您的人康账户名称、密码，或发生违反保密规定的任何其他情况，您应立即通知人康平台<span>；</span> (b) 确保您在每个上网时段结束时，以正确步骤离开网站/服务。人康不能也不会对因您未能遵守本款规定而发生的任何损失负责。您理解人康对您的请求采取行动需要合理时间，人康对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                三、&nbsp;人康平台服务
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;服务费用
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                a)&nbsp;您在使用人康平台服务过程中，您必须自行承担所需的硬件和相关费用；平台不对此承担责任；平台有权升级版本，并提前在平台和网站进行发布；请自动升级您的硬件。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                b)&nbsp;人康平台向您提供的服务，目前处于免费期。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                2.&nbsp;您在平台使用服务并与其它会员发生纠纷时，交易双方应友好协商解决，平台不承担因该纠纷产生的任何责任。如任何一方将交易纠纷提交人康平台要求调解处理，则平台作为独立第三方，有权独自判断做出参考决定，您了解并同意接受平台的判断和决定，同意接受平台因此采取的任何措施并不因任何原因追究平台的责任。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                四、&nbsp;人康平台服务使用规范
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;<b>在平台上使用</b><b>人康</b><b>服务过程中，您承诺不得从事以下行为：</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                a)&nbsp;<b>申请或者变更用户信息时提供虚假信息；</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                b)&nbsp;<b>盗用他人信息；</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                c)&nbsp;<b>利用任何方式危害</b><b>人康</b><b>平台或系统的安全；</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                d)&nbsp;<b>通过任何方式干扰或试图干扰平台的正常运营；</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                e)&nbsp;<b>为任何非法目的使用</b><b>人康</b><b>平台服务；</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                f)&nbsp;<b>在平台或系统上复制、发布任何形式的虚假或违法信息，或复制、发布含下列内容的信息：</b><b>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；损害国家荣誉和利益；煽动民族仇恨、民族歧视，破坏民族团结；破坏国家宗教政策，宣扬邪教和封建迷信</b><b>；</b><b>散布谣言，扰乱社会秩序，破坏社会稳定；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪</b><b>；</b><b>含有法律、行政法规禁止的其他内容。</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                g)&nbsp;<b>其他法律法规、本协议、</b><b>人康</b><b>各项规则和要求禁止从事的行为。</b><b></b>
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                3.&nbsp;您了解并同意：
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                a)&nbsp;您如果违反前述承诺，产生任何法律后果的，您应承担所有的法律责任，包括但不限于承担平台因此产生的任何损失或增加的任何费用，并接受平台不经通知您依规采取的措施或对您的处罚。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                b)&nbsp;对于您因使用任何不正确或不合法的连线方式导致的任何损失、支出、费用、罚金，本公司不承担任何责任。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                4.&nbsp;您不对人康平台上的任何数据作商业性利用，包括但不限于在未经人康事先书面同意的情况下，以复制、传播等任何方式使用人康平台站上展示的资料、人康LOGO标志等。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                五、&nbsp;特别授权
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;你完全理解并不可撤销地授权人康根据本协议及平台发布的规则的规定，处理您在人康平台上发生的所有交易及可能产生的交易纠纷。您同意接受人康或人康授权的第三方或您与人康一致同意的第三方的判断和决定。该决定将对您具有法律约束力。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                2.&nbsp;对于您的全部资料（包括但不限于注册资料、交易行为数据及全部展示于人康平台的各类信息），您授权人康及<span>其关联公司独家的、全球通用的、永久的、免费的许可使用权利</span> (并有权在多个层面对该权利进行再授权)。此外，人康及其关联公司有权使用、复制、修订、改写、发布、翻译、分发、执行和展示您的全部资料数据或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                3.&nbsp;您同意，平台有权应国家机关的要求，向其提供用户信息及交易记录等信息。如经平台初步判断，您存在涉嫌侵犯他人合法权益，本公司亦有权向权利人提供必要的用户信息。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                六、&nbsp;责任范围和责任限制
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;由于信息网络正常的设备维护，信息网络连接故障，电脑、通讯、黑客、病毒或其他系统的故障，电力故障，罢工，暴乱，火灾，洪水，战争，政府行为，司法行政机关的命令等不可抗力行为或第三方的不作为而造成的不能服务或延迟服务，本公司不承担责任。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                七、&nbsp;个人信息和商业秘密的收集与保护
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;人康平台对您的信息、平台中的各类商业信息采取技术手段进行合法保护。未经权利所有方同意，任何第三方不得在平台上采取包括但不限于盗取、复制、披露、转发等手段进行违法活动，一经查实，将提交司法机关处理。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                2.&nbsp;人康平台可能会与第三方合作向您提供相关的服务，在此情况下，如该第三方同意承担与平台同等的保护用户隐私的责任，则您同意授权平台可将您的信息提供给该第三方。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                3.&nbsp;在不透露单个用户隐私资料的前提下，平台有权对整个用户数据库进行技术分析并对已进行分析、整理后的用户数据库进行商业上的利用。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                八、&nbsp;协议终止
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;出现以下情况时，人康有权直接以注销账户的方式终止本协议，并有权永久冻结（注销）您的账户在人康平台的权限：
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                a)&nbsp;您提供的信息存在虚假或欺诈内容。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                b)&nbsp;您严重违反本协议约定或违反平台使用规则，且造成严重后果。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                c)&nbsp;本协议内容或交易规则等变更时，您明确通知人康不愿接受新的服务协议。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                d)&nbsp;其他人康认为应当终止服务的情况。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                2.&nbsp;您的服务被终止或者账户在人康平台的权限被永久冻结（注销）后，人康没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                3.&nbsp;您完全了解并同意：
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                a)&nbsp;本协议终止后，人康平台仍有权继续保存并使用您的各项信息。在服务终止之前已产生的交易行为，由您自行处理并承担全部责任。您在使用人康平台服务期间存在违法行为或违反本协议或规则的行为的，人康仍可依据本协议向您主张权利。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                b)&nbsp;您违反本协议而给平台带来的损失包括诉讼费用、政府罚款、向第三方支付的赔偿金及其他相关费用，平台有权不经通知您从账号中优先扣除全部或部分的金额，不足以弥补平台损失的，平台保留进一步追偿的权利。
            </p>
            <p class="MsoNormal">
                &nbsp;
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                九、&nbsp;法律适用、管辖与其他
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                1.&nbsp;本协议的订立、执行和解释及争议的解决均应适用中国法律。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                2.&nbsp;如您在使用平台服务过程中发生争议，争议双方应尽量友好协商解决；协商不成时，任何一方均应向平台所在地的人民法院提起诉讼。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                3.&nbsp;如本协议中的任何条款因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                本版本更新时间：2022年4月16日
            </p>
            <p class="15" style="margin-left:24.0000pt;text-indent:-24.0000pt;">
                本版本生效时间：2022年4月16日
            </p>
            <p class="MsoNormal">
                在人康<span>客户端上</span>/网站/微信公众号上公布的某些部分或页面中可能存在除本协议以外的单独的附加服务条款，当这些条款存在冲突时，在该些部分和页面中附加条款优先适用。
            </p>
        </div>
    </div>
</template>

<script>
    // import { Toast } from 'vant';
    export default {
        name: 'agreement',
        components: {
        },
        data() {
            return {
            }},
        created() {
        },
        mounted:function(){

        },
        methods: {
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        background-color:#ffffff;
        background-size: cover;
        box-sizing: border-box;
        position: relative;
    }
    .doc-content{
        padding: 20px;
    }
</style>
